import { VestingType } from "./ts/interfaces";

/* eslint-disable-next-line */
declare let window: any;

export const is_browser = typeof window !== "undefined";

export const types = ["Linear", "Monthly", "Interval"];

export const provider_is_meta_mask =
    is_browser &&
    typeof window.web3 !== "undefined" &&
    window.web3.currentProvider.isMetaMask === true;

export const NETWORKS = {
    1: {
        rpc: ["https://mainnet.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a"],
        symbol: "ETH",
        explorer_url: "etherscan.com",
        network_name: "Ethereum Mainnet",
    },
    3: {
        rpc: ["https://ropsten.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a"],
        symbol: "ETH",
        explorer_url: "ropsten.etherscan.com",
        network_name: "Ropsten",
    },
    4: {
        rpc: ["https://rinkeby.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a"],
        symbol: "ETH",
        explorer_url: "rinkeby.etherscan.com",
        network_name: "Rinkeby",
    },
    5: {
        rpc: ["https://goerli.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a"],
        symbol: "ETH",
        explorer_url: "georli.etherscan.com",
        network_name: "Georli",
    },
    56: {
        rpc: [
            "https://binance.llamarpc.com",
        ],
        symbol: "BNB",
        explorer_url: "bscscan.com",
        network_name: "Binance Smart Chain Mainnet",
    },
    97: {
        rpc: [
            "https://fittest-solemn-pond.bsc-testnet.quiknode.pro/1cebe50a86b1cfc4754f637e3ec09add4c4a7388/",
        ],
        symbol: "BNB",
        explorer_url: "testnet.bscscan.com",
        network_name: "Binance Smart Chain Testnet",
    },
    137: {
        rpc: ["https://polygon-rpc.com"],
        symbol: "MATIC",
        explorer_url: "polygonscan.com",
        network_name: "Polygon Mainnet",
    },
    80001: {
        rpc: ["https://rpc.ankr.com/polygon_mumbai"],
        symbol: "MATIC",
        explorer_url: "mumbai.polygonscan.com",
        network_name: "Polygon Mumbai Testnet",
    },
    43114: {
        rpc: ["https://api.avax.network/ext/bc/C/rpc"],
        symbol: "AVAX",
        explorer_url: "snowtrace.io",
        network_name: "Avalanche Mainnet",
    },
    43113: {
        rpc: ["https://api.avax-test.network/ext/bc/C/rpc"],
        symbol: "AVAX",
        explorer_url: "testnet.snowtrace.io",
        network_name: "Avalanche Fuji Testnet",
    },
    1337: {
        rpc: ["http://127.0.0.1:8545/"],
        symbol: "ETH",
        explorer_url: "testnet.bscscan.com",
        network_name: "Localhost",
    },
};

export const strategy_form_data = [
    {
        label: "Strategy name",
        name: "name",
        type: "text",
        placeholder: "Enter a strategy name",
        types: [VestingType.Linear, VestingType.Monthly, VestingType.Interval],
    },
    {
        label: "Start date",
        name: "start_date",
        type: "datetime-local",
        types: [VestingType.Linear, VestingType.Monthly, VestingType.Interval],
        inset: "UTC",
    },
    {
        label: "Cliff",
        name: "cliff",
        type: "datetime-local",
        types: [VestingType.Linear, VestingType.Monthly, VestingType.Interval],
        inset: "UTC",
    },
    {
        label: "End date",
        name: "end_date",
        type: "datetime-local",
        types: [VestingType.Linear],
        inset: "UTC",
    },
    {
        label: "Month gap",
        name: "interval",
        placeholder: "Specify the number of months between unlocks",
        type: "number",
        types: [VestingType.Monthly],
        inset: "months",
    },
    {
        label: "Interval",
        name: "interval",
        placeholder: "Specify the number of days between unlocks",
        type: "number",
        types: [VestingType.Interval],
        inset: "days",
    },
    {
        label: "Unlock per interval",
        name: "unlock_per_interval",
        placeholder: "Specify the unlock per interval",
        type: "number",
        types: [VestingType.Interval, VestingType.Monthly],
        inset: "%",
    },
    {
        label: "Initial unlock percent",
        name: "initial_unlock",
        type: "number",
        placeholder: "Specify initial unlock percent",
        tooltip:
            "Set the initial unlock percentage users will receive on the start date of the vesting. (max 1 decimal place)",
        types: [VestingType.Linear, VestingType.Monthly, VestingType.Interval],
        inset: "%",
    },
    {
        label: "Revocability",
        name: "revocable",
        type: "checkbox",
        placeholder: "Strategy is revocable",
        types: [VestingType.Linear, VestingType.Monthly, VestingType.Interval],
    },
];
